<template lang="pug">
    .w-full
        .relative.z-10.flex.items-center.mb-4
            .relative.flex.items-center
                .field.field--icon-left.w-full.mr-2
                    input.field-style(placeholder="Cari Laporan" v-model="keyword" @keyup="searchKeyword")
                    span.icon-field
                        b-icon(name="search")
                .box-calendar
                    b-icon(name="calendar")
                    date-range-picker(
                        v-if="dateRange.startDate && dateRange.endDate"
                        ref="picker"
                        v-model="dateRange"
                        @update="pickerUpdate($event)"
                        :locale-data="{ firstDay: 1, format: 'dd mmmm yyyy', applyLabel: 'Terapkan', cancelLabel: 'Batalkan', }"
                        :date-format="dateFormatPicker"
                        opens="left"
                        drops="up"
                        :ranges="default_ranges"
                        autoApply=false
                    )
                        //- @toggle="showCalendar()"
                        template(slot="input" slot-scope="picker" style="min-width: 350px;") {{ picker.startDate | date }} - {{ picker.endDate | date }}

        .card-bg-theme.p-6.rounded.shadow.w-full
            .flex.items-center.gap-4(v-if="list_report.length > 0")
                h2.heading.font-bold.mb-4 {{ list_report.length }} laporan ditemukan
                .label-alert.items-center.rounded.mb-4
                    .label-alert__cover
                        b-icon(name="calendar" size="small")
                    .label-alert__info
                        p.text-xs Data laporan diambil dari rentang waktu <strong> {{ filterStartDate }} - {{ filterEndDate }} </strong>
            .box-report
                ul(v-if="list_report.length > 0")
                    li(v-for="item in list_report")
                        button(@click="openModalReportAction(item.judul_laporan)")
                            .w-40
                                .box-label-source
                                    b-icon(name="file-text" size="small").mr-2
                                    span.text-xs.font-bold.uppercase laporan
                            .block
                                p {{ item.judul_laporan }}
                                span.text-xs {{ datetimeFormat(checkString(item.tanggal_laporan), 'YYYY-MM-DDTHH:mm:ssZ',('DD MMM YYYY')) }}

                template(v-if="list_report.length > 0 || status_search_report.status === 'loading'")
                            .relative.mt-5.mb-5(v-if="status_search_report.status === 'loading'")
                                b-loading(kind="circular")
                            template(v-else-if="list_report.length > 0 && (search_report && search_report.pagination && search_report.pagination.hasNext == true)")
                                .flex.items-center.justify-center.w-full
                                    b-button(text="Tampilkan laporan lainnya..." kind="tertiary" size="small" @click.native="loadMoreReport()")

                .flex.items-center.justify-center(v-else style="min-height:414px")
                    span Laporan tidak ditemukan, cari laporan berdasarkan kata kunci.

        //- POPUP DETAIL LAPORAN
        .modal.modal--filled(v-bind:class="{'modal--show' : openModalReport}" style="z-index: 9999" ref="bigSearchDetailReport")
            .modal-container
                .modal-container__header
                    .flex-grow
                        h2 Laporan
                    b-button(icon_only="x" @click.native="openModalReport = !openModalReport; titleModalReport = '';" type="button")
                .modal-container__body
                    .relative.mt-10(v-if="status_detail_report === 'loading'")
                        b-loading(kind="circular")

                    template(v-else)
                        h2.subheading {{ titleModalReport }}
                        template(v-if="detail_report && Object.keys(detail_report).length > 0")
                            //- p Lorem ipsum dolor sit amet, cons
                            //- p(v-for="(item,index) in detail_report" :key="index") {{ item.description_raw ? item.description_raw : item.description }}
                            //- p(v-for="(item,index) in detail_report" :key="index") {{ item.description }}
                            div(v-for="(item,index) in detail_report" :key="index")
                                hr
                                p.mt-5.mb-5(v-html="JSON.parse(item.description_raw)")

                        .block.text-center.mb-5(v-else)
                            hr.mb-10
                            span Detail laporan tidak ditemukan.


</template>

<script>
import { mapState } from 'vuex';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';

export default {
    name: 'SearchReport',
    components: {
        DateRangePicker,
    },
    data() {
        return {
            keyword: '',
            timeout: null,
            dateRange: {
                // startDate: moment().subtract(1, 'month').toDate(),
                startDate: this.getDefaultFromDate(),
                endDate: moment().toDate(),
            },
            default_ranges: {
                'Hari ini': [moment().toDate(), moment().toDate()],
                'Kemarin': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                '7 hari lalu': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Minggu lalu': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },

            page_report: 1,
            limit_report: 10,
            list_report: [],
            titleModalReport: 'title',
            openModalReport: false,
        };
    },
    computed: {
        ...mapState('news', [
            'status_search_report',
            'search_report',
            'status_detail_report',
            'detail_report',
        ]),
        route_name() {
            return this.$route.name;
        },
        filterStartDate() {
            return moment(this.dateRange.startDate).locale('id').format('DD MMMM YYYY');
        },
        filterEndDate() {
            return moment(this.dateRange.endDate).locale('id').format('DD MMMM YYYY');
        },
    },
    watch: {
        filterStartDate() {},
        filterEndDate() {},
        search_report(newVal) {
            // console.log(newVal);
            if (newVal.doc && newVal.doc.length > 0) {
                for (let i = 0; i < newVal.doc.length; i++) {
                    this.list_report.push(newVal.doc[i]);
                }
            }
        },
    },
    methods: {
        searchKeyword() {
            clearTimeout(this.timeout);
            const self = this;
            this.timeout = setTimeout(function() {
                self.getSearchReport(true);
            }, 650);
        },
        async pickerUpdate(e) {
            this.getSearchReport(true);
        },
        loadMoreReport() {
            this.page_report += 1;
            this.getSearchReport();
        },
        openModalReportAction(title) {
            this.titleModalReport = title;
            this.$store.dispatch('news/getDetailReport', title);
            this.openModalReport = !this.openModalReport;
        },
        getSearchReport(reset) {
            if (reset) {
                this.list_report = [];
                this.page_report = 1;
                this.$store.commit('news/setSearchReport', {});
            }

            const dateFrom = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            const dateTo = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.$store.dispatch('news/getSearchReport', [this.limit_report, this.page_report, this.keyword, dateFrom, dateTo]);
        },
    },
    created() {
        // 
    },
    filters: {
        date: function(date) {
            return moment(date).locale('id').format('DD MMM YYYY');
        },
    },
    // mounted() {
    //     // 
    // },
};
</script>

<style lang="sass">

</style>
